import React, { Fragment } from "react";
import { Copy, Section, Columns, Column } from "./Widgets";

const FinePrint = () => {
  return (
    <Fragment>
      <Section>
        <Columns opt="is-centered">
          <Column sz="is-8">
            <Copy>
              The California Gold Surf Auction has combined sales of over $3M.
              The auction committee is focusing on unique surfboards and
              memorabilia with from the ’20s through the ’90s. We’ve already
              coalesced a solid selection of “Gold’ from and are actively
              searching for more. If you’re a “motivated seller” please contact
              us. We’re anticipating between 40 – 50 prime surfboards for the
              timed auction and at least 25 memorabilia items. Exciting auction
              history since 2013 (with the help of many friends including Randy
              Rarick, Fernando Aguerre, Dan Pincetich, Keith Eshelman, Dick
              Metz, Spencer Croul, the Surfing Heritage & Culture Center, Steve
              Collins and the Vintage Surfboard Collector Club). Please put the
              CALIFORNIA GOLD SURF AUCTION on you calendar now and DOWNLOAD THE
              APP TO BID AND WIN!
            </Copy>
          </Column>
        </Columns>
      </Section>
    </Fragment>
  );
};

export default FinePrint;
