import React, { Fragment } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
const CustomForm = ({ status, message, onValidated }) => {
  let email, name;
  const submit = () =>
    email &&
    name &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
      NAME: name.value
    });

  return (
    <Fragment>
      <section className="modal-card-body">
        {status === "sending" && (
          <div style={{ color: "blue" }}>sending...</div>
        )}
        {status === "error" && (
          <div
            style={{ color: "red" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            style={{ color: "green" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        <div className="field">
          <div className="control">
            <input
              className="input is-warning"
              ref={node => (name = node)}
              type="text"
              placeholder="Your name"
            />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <input
              className="input is-warning"
              ref={node => (email = node)}
              type="email"
              placeholder="Your email"
            />
          </div>
        </div>
      </section>
      <footer className="modal-card-foot">
        <button className="button is-success" onClick={submit}>
          Submit
        </button>
        <button className="button">Cancel</button>
      </footer>
    </Fragment>
  );
};

const handleClick = () => {
  document.getElementById("news-modal").classList.toggle("is-active");
};

const Modal = () => {
  const url =
    "https://Boardroomshow.us12.list-manage.com/subscribe/post?u=3eaaf8b83abff0f3a474efcde&amp;id=b22ff7ac6d";
  return (
    <Fragment>
      <div id="news-modal" className="modal">
        <div onClick={handleClick} className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title fonty is-size-3">
              Sign Up to Our Mailing List
            </p>
            <button
              onClick={handleClick}
              className="delete"
              aria-label="close"
            />
          </header>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={formData => subscribe(formData)}
              />
            )}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Modal;
