import React, { Fragment } from "react";
import { Link } from "@reach/router";
// import hero from "./images/Vintage_surf_auctions_logo.png";
import hero from "./images/fall-24-logo.jpg";
// import lightning from "./images/main header-CGSA-Lightning Bolts.jpg";
// import lightning from "./images/mainheaderCGSA-1400x575_v2.jpg";
// import gsBoard from "./images/1200-x628_CalGold-Google.jpg";
import "./App.scss";
import Navbar from "./Navbar";

const Header = () => {
  return (
    <Fragment>
      <header className="App-header">
        <h1 className="title is-1 fonty push-top">2024</h1>
        <Link to="/">
          <h1 className="title is-1 fonty">
            California <span style={{ color: "gold" }}>Gold </span>
            Surf Auction
          </h1>
        </Link>
        <img src={hero} className="center sm-hero" alt="logo" />
        <hr />
        <Navbar />
        {/* <img src={gsBoard} className="center" alt="logo" /> */}
      </header>
    </Fragment>
  );
};

export default Header;
