import React from "react";
import { Link } from "@reach/router";

const Navbar = () => {
  return (
    <div className="columns is-centered">
      <div className="column is-9">
        <ul className="mini-bar">
          <li>
            <a
              href="https://auctions.thevintagesurfauctions.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              Go To Auction
            </a>
          </li>
          <li>
            <a
              href="https://itunes.apple.com/us/app/california-gold-surf-auction/id1206528272"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              Download App
            </a>
          </li>
          {/* <li>
            <a
              href="https://boardroomshow.ticketspice.com/boardroomshow"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              Buy Tickets
            </a>
          </li> */}
          {/* <li>Vip Lounge</li> */}
          <li>
            <Link to="/terms-and-conditions" className="link">
              Terms & Conditions
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
