import React, { Fragment } from "react";
import appImg from "./images/california_gold_app.jpg";

const DownloadApp = () => {
  return (
    <Fragment>
      <section className="section">
        <h2 className="title is-2 ">
          Download the <span className="gold">App</span>
        </h2>
        <img
          src={appImg}
          alt="California Gold Surf Auction App Screenshots"
          className="is-rounded"
        />
      </section>
    </Fragment>
  );
};

export default DownloadApp;
