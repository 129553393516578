import React, { Fragment } from "react";

const TopCopy = () => {
  return (
    <Fragment>
      <section className="section super">
        <div className="columns">
          <div className="column">
            <h3 className="title is-1 is-size-3-touch">
              Fall Auction October 5th - October 19th
            </h3>
            <hr />
          </div>
        </div>
      </section>
      <section className="section">
        <div className="columns">
          <div className="column">
            <p className="push-top has-text-left">
              <span className="title is-1 fonty ">
                The California <span className="gold">Gold</span> Surf Auction
                &nbsp;
              </span>
              serves the global audience of premium surfboard and surf
              memorabilia collectors providing perpetual access to the
              marketplace. With decades of historical expertise, and millions of
              dollars in sold inventory, we are uniquely positioned to curate,
              preserve and share culturally significant surfboards and
              collectibles. Discretion, trust and passion are foundational
              pillars our buyer and sellers count on to maintain the integrity
              of the the market and their investments. Our constantly adapting
              technological innovation allows around the clock access and the
              ability to be involved;
              <em> anyplace,</em> <em>anywhere,</em> <em>anytime</em>.
            </p>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="columns">
          <div className="column">
            <p className="subtitle is-3">
              Register to Bid -{" "}
              <a
                href="https://auctions.thevintagesurfauctions.com"
                target="_blank"
                className="is-gold button is-large"
                rel="noopener noreferrer"
              >
                Click Here to Login
              </a>
            </p>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default TopCopy;
