import "bulma";
import React, { Component } from "react";
import { Router } from "@reach/router";
import Header from "./Header";
import Footer from "./Footer";
import Home from "./Home";
import Terms from "./Terms";

class App extends Component {
  render() {
    return (
      <div className="App container">
        <Header />
        <Router>
          <Home path="/" />
          <Terms path="/terms-and-conditions" />
        </Router>
        <Footer />
      </div>
    );
  }
}

export default App;
