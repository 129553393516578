import React, { Fragment } from "react";
import TopCopy from "./TopCopy";
// import SneakPeak from "./SneakPeak";
import FinePrint from "./FinePrint";
import DownloadApp from "./DownloadApp";
import Modal from "./Modal";

const Home = () => {
  return (
    <Fragment>
      <TopCopy />
      {/* <SneakPeak /> */}
      <DownloadApp />
      <FinePrint />
      <Modal />
    </Fragment>
  );
};

export default Home;
