import React, { Fragment } from "react";
import {
  SubHeading,
  Copy,
  Section,
  Columns,
  Column,
  Title,
  ListNumber
} from "./Widgets";

const Terms = () => {
  return (
    <Fragment>
      <Title>Terms & Conditions</Title>
      <Section>
        <Columns opt="is-centered">
          <Column sz="is-8">
            <Copy>
            A 15% buyers premium is added to all winning final bids. Some bids subject to a California State Sales Tax. All items must be paid in full on the day of the sale. No purchases may be removed until paid in full.
            </Copy>
            <SubHeading>Form of Payment</SubHeading>
            <Copy>
            All payments must be made by Master Card/VISA credit cards. Credit Card on file will be charged immediately following winning bid closure
            </Copy>
            <SubHeading>Estimates</SubHeading>
            <Copy>
            The price estimates given have been drawn from previous auctions, collector sales and current going rates at the time of publication. However, it should be noted that these are purely estimates of the range of prices that items could bring. This shall give bidders an idea of what the bidding range is expected to be. There is no guarantee that prices will fall within this range. In some case, individual items may vary in price, due to the exceptional quality or rarity of the item.
            </Copy>
            <SubHeading>Reserves</SubHeading>
            <Copy>
            Many items will have no reserve. For those that do, it is usually the case that without a reserve, the item would not be offered. Reserve prices will normally be reflected in the low estimated value.
            </Copy>
            <SubHeading>Preview Auction</SubHeading>
            <Copy>
            All auction items will be available for preview via the California Gold Surf Auction app available for free download to your phone, tablet or desk top computer.
Anyone who downloads the California Gold Surf Auction app can preview the auction lots.
You must register with a credit card prior to bidding to receive a bid number.
            </Copy>
            <SubHeading>Sales</SubHeading>
            <Copy>
            All sales are final upon announcement by Auctioneer as “sold” to the highest bid OR in the case of a timed sale, when the time is up and a soft close of the lot item is not occurring. A soft close occurs when two or more bidders are actively bidding on the item at the time of sale closure. The highest Bidder shall be the Purchaser and no Purchaser shall retract his bid. All sales are final. However, a registered buyer’s bid (an offer) is not a sale when it is less than a minimum reserve on that item. In this case, the auctioneer will say “offer” at the close of the bidding process. The Bidder must contact the owner/ principals to find out if offer is accepted. It is the responsibility of the Purchaser to insure his purchases immediately.
            </Copy>
            <SubHeading>Terms of Sale</SubHeading>
            <ol className="has-text-left push-left-sm">
              <ListNumber>
              All purchases must be removed from the Auction Site the day of the auction and all costs, responsibility and risk of such removal shall be borne by Purchaser. If for any reason the Purchaser fails to remove any lot within the time specified (see shipping below), the lot shall be deemed abandoned, and the Auctioneer at his sole discretion may resell or remove and store the said lot at Purchaser’s sole risk and expense. Purchaser shall be liable for any rent incurred or damages suffered by the Auctioneers because of Purchaser’s failure to remove any lot.
              </ListNumber>
              <ListNumber>
              There shall be no guarantee or warranties, expressed or implied. Each and every lot will be sold “as is, where is”. The Bidder agrees that he has satisfied himself and is not relying on the Auctioneers, nor are Auctioneers liable.
              </ListNumber>
              <ListNumber>
              Any dispute arising as to any bidding shall be settled by the Auctioneers at their sole discretion and the Auctioneer may, immediately, put the lot in dispute up again for sale. The Auctioneers reserves the right to refuse any bid which the Auctioneers consider to be an insignificant advance over the preceding bid.
              </ListNumber>
              <ListNumber>
              The Auctioneers reserve the right to refuse any applicant for bidding card the privilege of bidding at this auction sale and to revoke such privilege at any time.
              </ListNumber>
              <ListNumber>
              Purchaser acknowledges that prior to the auction all lots were available for inspection.
              </ListNumber>
              <ListNumber>
              Auctioneers may, at any time, at the their sole discretion and without notice or liability to bidder:
                <ol className="push-left-sm">
                  <ListNumber>
                    withdraw or cancel the sale of any lot, or
                  </ListNumber>
                  <ListNumber>
                    offer any lots in groups or with privilege.
                  </ListNumber>
                </ol>
              </ListNumber>
              <ListNumber>
                A purchaser is deemed to have accepted a lot when they make a
                bid.
              </ListNumber>
              <ListNumber>
              Bidder acknowledges that an Auction Site is a potentially dangerous place. Flammable, noxious, corrosive and pressurized substances are present, heavy equipment is being operated and electrical circuits may be live. Every person at the Auction Site before, during and after the auction sale shall be deemed to be there at his own risk with notice of the condition of the premises, the activities on the premises and the conduct of third parties and the Bidder shall so advise his agents and employees. No person shall have any claim, demands or causes of action, whether at law or in equity, against the Auctioneers their agents, employees or principals for any injuries sustained nor for damages to loss of property which may occur from any cause what so ever.
              </ListNumber>
              <ListNumber>
                The purchaser / winning bidder is solely responsible for the
                safe and timely pick-up and removal of any items you may win at
                this auction.
              </ListNumber>
              <ListNumber>
                After two (2) weeks any unclaimed property will be assessed a
                weekly space rental fee of $100 per item, payable prior to item
                being released.
              </ListNumber>
              <ListNumber>
                These Terms of Sale may not be amended except by agreement in
                writing signed by the Auctioneers and the respective Purchaser.
              </ListNumber>
            </ol>
            <SubHeading>Packaging and Shipping</SubHeading>
            <Copy>
              Purchaser is required to pick up board the day of the auction OR
              make appropriate shipping plans.
              Packing and shipping is the responsibility of the purchaser / winning bidder.
            </Copy>
            <Copy>
            Boards/lots will be packed and shipped using the purchaser / winning bidder shipping company of choice. Purchaser / winning bidder is responsible for arranging packing and shipping. Purchasers / winning bidders shall be responsible for all packing and shipping costs incurred. Packing and shipping arrangements must be made and communicated to the the auction house within two weeks of auction closing.
            </Copy>
          </Column>
        </Columns>
      </Section>
    </Fragment>
  );
};

export default Terms;
