import React from "react";
import { Form, Field } from "react-final-form";

const handleClick = () => {
  document.getElementById("contact-modal").classList.toggle("is-active");
};

const url =
  "https://dv93iv2djc.execute-api.us-east-1.amazonaws.com/dev/email/send";

const post = (url, body, callback) => {
  let req = new XMLHttpRequest();
  req.open("POST", url, true);
  req.setRequestHeader("Content-Type", "application/json");
  req.addEventListener("load", function() {
    if (req.status < 400) {
      callback(null, JSON.parse(req.responseText));
    } else {
      callback(new Error("Request failed: " + req.statusText));
    }
  });
  req.send(JSON.stringify(body));
};

const success = () => {
  console.log("sent");
};

// Scott@CaliforniaGoldSurfAuction.com
const ContactModal = () => {
  return (
    <div id="contact-modal" className="modal">
      <div className="modal-background" onClick={handleClick} />
      <div className="modal-card">
        <MyForm />
      </div>
    </div>
  );
};
const onSubmit = async values => {
  const payload = values;
  console.log(values);
  post(url, payload, function(err, res) {
    if (err) {
      return error(err);
    }
    success();
  });
};

const error = err => {
  console.log(err);
};

const MyForm = () => (
  <Form
    onSubmit={onSubmit}
    validate={values => {
      const errors = {};
      if (!values.fullName) {
        errors.fullName = "Required";
      }
      if (!values.email) {
        errors.email = "Required";
      }
      if (!values.message) {
        errors.message = "Required";
      }
      return errors;
    }}
    render={({ handleSubmit, reset, submitting, pristine, values }) => (
      <form onSubmit={handleSubmit}>
        <header className="modal-card-head">
          <p className="modal-card-title fonty">Contact Us</p>
          <button className="delete" aria-label="close" onClick={handleClick} />
        </header>
        <section className="modal-card-body">
          <Field name="fullName">
            {({ input, meta }) => (
              <div className="field">
                <div className="control">
                  <input
                    {...input}
                    type="text"
                    placeholder="Name"
                    className="input"
                  />
                  {meta.error &&
                    meta.touched && (
                      <span className="has-text-danger">{meta.error}</span>
                    )}
                </div>
              </div>
            )}
          </Field>
          <Field name="email">
            {({ input, meta }) => (
              <div className="field">
                <div className="control">
                  <input
                    {...input}
                    type="email"
                    placeholder="Email"
                    className="input"
                  />
                  {meta.error &&
                    meta.touched && (
                      <span className="has-text-danger">{meta.error}</span>
                    )}
                </div>
              </div>
            )}
          </Field>
          <Field name="message">
            {({ input, meta }) => (
              <div className="field">
                <div className="control">
                  <textarea
                    {...input}
                    type="text"
                    placeholder="Message"
                    className="textarea"
                  />
                  {meta.error &&
                    meta.touched && (
                      <span className="has-text-danger">{meta.error}</span>
                    )}
                  {meta.submitSucceeded && (
                    <h3 className="is-3 has-text-info">
                      Thank you for contacting us. We will be in touch shortly.
                    </h3>
                  )}
                </div>
              </div>
            )}
          </Field>
        </section>
        <footer className="modal-card-foot">
          <div id="toast" />
          <button
            type="submit"
            disabled={submitting}
            className="button is-success"
          >
            Submit
          </button>
          <button
            type="button"
            className="button"
            onClick={reset}
            disabled={submitting || pristine}
          >
            Reset
          </button>
        </footer>
      </form>
    )}
  />
);

export default ContactModal;

// const form = document.getElementById("contactForm");
// const submit = document.getElementById("submit");
