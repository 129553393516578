import React, { Fragment, Component } from "react";
import Cookies from "js-cookie";
import ContactModal from "./ContactModal";
import { Section, Columns, Column } from "./Widgets";

class Footer extends Component {
  componentDidMount() {
    setTimeout(function() {
      let popupShown = Cookies.get("popShown");

      if (!popupShown) {
        document.getElementById("news-modal").classList.toggle("is-active");
        Cookies.set("popShown", "true", { expires: 0.1 });
      }
    }, 20000);
  }
  handleClick = () => {
    document.getElementById("contact-modal").classList.toggle("is-active");
  };
  newsPop = () => {
    document.getElementById("news-modal").classList.toggle("is-active");
    Cookies.set("popShown", "true", { expires: 0.1 });
  };
  render() {
    return (
      <Fragment>
        <Section opt="super">
          <Columns>
            <Column>
              <button
                className="button is-gold is-large"
                onClick={this.handleClick}
              >
                Contact
              </button>
            </Column>
            <Column>
              <button
                className="button is-gold is-large"
                onClick={this.newsPop}
              >
                Mailing List
              </button>
            </Column>
          </Columns>
          <Columns>
            <Column>
              <p>Copyright 2019 California Gold Surf Auction &trade;</p>
            </Column>
          </Columns>
        </Section>
        <ContactModal />
      </Fragment>
    );
  }
}

export default Footer;
