import React from "react";

export const SubHeading = ({ children }) => {
  return <h3 className="title is-4 has-text-left push-top-sm">{children}</h3>;
};

export const Copy = ({ children }) => {
  return <p className="has-text-left list-block">{children}</p>;
};

export const Section = ({ children, opt }) => {
  return <section className={`section ${opt}`}>{children}</section>;
};

export const Columns = ({ children, opt }) => {
  return <div className={`columns ${opt}`}>{children}</div>;
};

export const Column = ({ children, sz }) => {
  return <div className={`column ${sz}`}>{children}</div>;
};

export const Title = ({ children }) => {
  return <h1 className="title is-3">{children}</h1>;
};

export const ListNumber = ({ children }) => {
  return <li className="p push-left-xsm list-block">{children}</li>;
};
